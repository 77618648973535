.hero {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.65)
    ),
    url("../../../images/sinhasana/cover.jpg");
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.hero-content {
  text-align: left;
}
