.team {
  .card {
    color: #fff;
    border-color: white;
    background-color: get-color(dark, 2);
    border: none;
  }

  .line {
    color: #fff;
  }
}
