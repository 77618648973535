// Team (core/sections/_testimonial.scss) ---------------
$team--padding-t__mobile: null; // team, top padding (mobile)
$team--padding-b__mobile: null; // team, bottom padding (mobile)
$team--padding-t__desktop: null; // team, top padding (desktop)
$team--padding-b__desktop: null; // team, bottom padding (desktop)
$team-header--padding__mobile: null; // team header, bottom padding (mobile)
$team-header--padding__desktop: null; // team header, bottom padding (desktop)
$team-items--padding__mobile: null; // padding between team items (mobile)
$team-items--padding__desktop: null; // padding between team items (desktop)
$team-item--width: 344px; // team item, width
$team-item--inner-padding-v: 24px; // team item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$team-item--inner-padding-h: 32px; // team item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
